<template>

  <b-card-code>

    <!-- forms -->
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="$emit('on-Submit', userAddForm)">
        <b-row col>
          <b-col md="6">
            <b-form-group
              label="First Name"
              label-for="mc-first-name"
            >
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required|alpha|max:60|min:3"
              >
                <b-form-input
                  v-model="userAddForm.first_name"
                  class="first_name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="First Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Last Name"
              label-for="mc-last-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required|max:60|alpha|min:3"
              >
                <b-form-input
                  v-model="userAddForm.last_name"
                  class="last_name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Last Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Role"
              rules="required"
            >
              <b-form-group
                label="Select Role"
                label-for="mc-select-role"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="userAddForm.role_id"
                  class="role"
                  placeholder="Select Role"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Gender"
            >
              <b-form-group
                label="Select Gender"
                label-for="mc-select-gender"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="userAddForm.gender"
                  class="gender"
                  placeholder="Select Gender"
                  :state="errors.length > 0 ? false:null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="genderOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="mc-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email|max:254"
              >
                <b-form-input
                  v-model="userAddForm.email"
                  class="email"
                  :readonly="isLoaded"
                  :state="errors.length > 0 ? false:null"
                  type="email"
                  placeholder="Your Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="!isLoaded" md="6">
            <b-form-group
              label="Password"
              label-for="mc-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:8|password"
              >
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">

                  <b-form-input
                    id="password"
                    v-model="userAddForm.password"
                    class="password"
                    :type="passwordFieldTypeNew"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Your Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer togglePasswordNew"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="!isLoaded" md="6">
            <b-form-group
              label="Confirm Password"
              label-for="mc-confirm-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password Confirm"
                rules="required|min:8|confirmed:Password"
              >
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">

                  <b-form-input
                    v-model="userAddForm.confirm_password"
                    class="confirm_password"
                    :state="errors.length > 0 ? false:null"
                    :type="passwordFieldTypeConfirm"
                    placeholder="Confirm Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconConfirm"
                      class="cursor-pointer togglePasswordConfirm"
                      @click="togglePasswordConfirm"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Send Email & IsActive check box -->
          <b-col md="6">
            <b-form-group v-if="!isLoaded"
                          label="Send Email"
                          label-for="mc-sendemail"
            >
              <b-form-checkbox
                v-model="userAddForm.send_email"
                class="send_email"
              />
            </b-form-group>
            <b-form-group v-if="isLoaded"
                          label="Is Active"
                          label-for="mc-isactive"
            >
              <b-form-checkbox
                v-model="userAddForm.is_active"
                class="is_active"
              />
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col md="12" class="demo-inline-spacing">
            <b-button
              :disabled="loading"
              variant="primary"
              type="submit"
            >
              Submit
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
            <b-button

              variant="secondary"
              :to="{name: ((checkLoginRole() === RoleEnum.SuperAdmin) || checkAccess.view)?'users-users-list':'dashboard'}"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import {
  required, email, confirmed, alpha, password
} from '@core/utils/validations/validations'
import vSelect from 'vue-select'
import { roleOptions, genderOptions, RoleEnum } from '@/helpers/constant'
import checkLoginRole from '@/helpers/checkLoginRole'
import accessRightCheck from '@/helpers/accessRightCheck'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    userDetails: {
      type: Object,
      required: true
    },
    isLoaded: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      userAddForm: {
        email: this.userDetails?.email || "",
        first_name: this.userDetails?.firstName || "",
        last_name: this.userDetails?.lastName || "",
        role_id: this.userDetails?.roleId || RoleEnum.Admin,
        gender: this.userDetails?.gender || "",
        password: '',
        confirm_password: '',
        send_email: false,
        is_active: this.userDetails?.isActive || false,
      },
      required,
      password,
      email,
      confirmed,
      alpha,
      RoleEnum,
      checkLoginRole,
      loading: false,
      roleOptions,
      checkAccess: {},
      genderOptions,
      passwordFieldTypeConfirm: 'password',
      passwordFieldTypeNew: 'password',
    }
  },

  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    passwordToggleIconConfirm() {
      return this.passwordFieldTypeConfirm === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },

  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name)
  },
  methods: {
    togglePasswordConfirm() {
      this.passwordFieldTypeConfirm = this.passwordFieldTypeConfirm === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    async validationForm() {
      let valid = null
      await this.$refs.simpleRules.validate().then(success => {
        if (success) {
          valid = true
        } else {
          valid = false
        }
      })
      return valid
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
