<template>
  <div id="app">
    <!-- <h3></h3> -->
    <UserAdd v-if="!id && !isLoaded" ref="simpleRules" :key="$route.fullPath" :is-loaded="isLoaded" :user-details="userDetails" @on-Submit="createUser" />

    <!-- <h3>Edit User form</h3> -->
    <UserAdd v-if="id && isLoaded" ref="simpleRules" :key="$route.fullPath" :is-loaded="isLoaded" :user-details="userDetails" @on-Submit="updateUser" />
  </div>
</template>

<script>

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import errorResponseHandler from '@/services/errorHandler'
import { RoleEnum, resConditionCheck } from '@/helpers/constant'
import accessRightCheck from '@/helpers/accessRightCheck'
import checkLoginRole from '@/helpers/checkLoginRole'
import UserAdminService from '../../../../services/user-admin/userAdmin.service'
import UserAdd from "./UserAdd.vue";

export default {
  name: "UserAddEdit",
  components: { UserAdd },
  data() {
    return {
      isLoaded: false,
      userDetails: {},
      id: this.$route.params.id ? this.$route.params.id : '',
      checkAccess: {},
      checkLoginRole
    };
  },
  watch: {
    async $route() {
      if (this.$route.name === 'user-add') {
        this.id = ''
        this.userDetails = {}
        this.isLoaded = false
      } else if (this.$route.name === 'user-edit') {
        this.id = this.$route.params.id
        await this.getUserById(this.$route.params.id);
        this.isLoaded = true
      }
    }
  },
  // When Page Reload & Refresh
  async mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name)
    if (this.$route.params.id) {
      await this.getUserById(this.$route.params.id);
    }
  },
  methods: {
    // Create Admin User
    async createUser(userForm) {
      const valid = await this.$refs.simpleRules.validationForm()
      if (valid) {
        this.$refs.simpleRules.loading = true
        const { is_active, ...userData } = userForm

        UserAdminService.createAdmin(userData).then(res => {
          this.$refs.simpleRules.loading = false

          if (resConditionCheck(res.status) && res.data.data) {
            if (this.checkAccess.view || checkLoginRole() === RoleEnum.SuperAdmin) {
              this.$router.push('/users/admin-users/list')
            } else {
              this.$router.push('/dashboard')
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        }).catch(error => {
          setTimeout(() => {
            this.$refs.simpleRules.loading = false
          }, 3000)
          const errorData = errorResponseHandler(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        })
      }
      // call an API to create a new user
    },

    // Update Admin User
    async updateUser(userForm) {
      const valid = await this.$refs.simpleRules.validationForm()
      if (valid) {
        this.$refs.simpleRules.loading = true
        const
          {
            password, confirm_password, send_email, ...editData
          } = userForm

        UserAdminService.updateAdmin(this.id, editData).then(res => {
          this.$refs.simpleRules.loading = false
          if (resConditionCheck(res.status) && res.data.user) {
            if (this.checkAccess.view || checkLoginRole() === RoleEnum.SuperAdmin) {
              this.$router.push('/users/admin-users/list')
            } else {
              this.$router.push('/dashboard')
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        }).catch(error => {
          this.$refs.simpleRules.loading = false
          const errorData = errorResponseHandler(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        })
      }
      // call an API to update the existing user
    },
    // When Route Refersh Check Which Route & Based On View Set with Data
    async getUserById(id) {
      await UserAdminService.getAdminById(id).then(res => {
        this.isLoaded = true;

        if (resConditionCheck(res.status) && res.data.data) {
          this.userDetails = res.data.data
        }
      }).catch(error => {
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      })
    },
  },
};
</script>
